<template>
  <b-modal
    id="modal-tiktok"
    v-model="isShow"
    centered
    no-close-on-backdrop
    no-close-on-esc
  >
    <template #modal-header>
      <div class="header-filter no-gutters content-between w-100">
        <div class="ft-title">Connect Tiktok Shop</div>
        <div class="text-right">
          <button
            type="button"
            aria-label="Close"
            class="close text-right"
            @click="$bvModal.hide('modal-tiktok')"
          >
            ×
          </button>
        </div>
      </div>
    </template>

    <b-row class="align-items-center">
      <b-col cols="12">
        <div class="ft-title mb-3"><b>Tiktok Shop Connect List</b></div>
      </b-col>
      <template v-for="items of shopList">
        <b-col cols="2" :key="'check-' + items.shop_code">
          <b-form-checkbox
            v-model="shopSelectedList"
            :aria-describedby="items.shop_code"
            :aria-controls="items.shop_code"
            :name="items.shop_code"
            :value="items.shop_id"
            disabled
          >
          </b-form-checkbox>
        </b-col>
        <b-col cols="10" :key="'name-' + items.shop_code">
          {{ items.shop_name }}
        </b-col>
      </template>
    </b-row>
    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button
          class="btn-cancel btn-width"
          @click="$bvModal.hide('modal-tiktok')"
          >Cancel
        </b-button>
        <b-button class="main-color btn-save" @click="submit()">
          Save
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      isShow: false,
      shopSelectedList: [],
      shopList: [],
      shop: null,
      token: null,
      selectShop: null,
    };
  },
  watch: {},
  methods: {
    show(data) {
      this.isShow = true;

      this.token = data.token;
      this.shopSelectedList = data.shop.data.shop_list
        .filter((el) => data.token.data.seller_name == el.shop_name)
        .map((el) => el.shop_id);
      this.shopList = data.shop.data.shop_list.filter((el) =>
        this.shopSelectedList.includes(el.shop_id)
      );
    },
    async submit() {
      this.$bus.$emit("showLoading");
      let payload = {
        connect_shop: this.shopList.filter((el) =>
          this.shopSelectedList.includes(el.shop_id)
        ),

        token: this.token,
      };
      let location = window.location.hostname;
      let brand = location.split("-")[0];
      let code = "DEV";
      if (brand == "nissin") code = "NS";
      else if (brand == "seasun") code = "SEASUN";
      else if (brand == "mille") code = "ML";
      else if (brand == "lolane") code = "LOLANE";
      else if (brand == "yongcharoen") code = "YCR";
      const result = await this.$Axios.post(
        `${process.env.VUE_APP_ECOMMERCE_API}/Tiktok/connect/${code}`,
        payload
      );

      this.$bus.$emit("hideLoading");
      if (result.data.result == 1) {
        this.successAlert();
        this.$emit("fetch");
        this.$router.replace({
          path: "",
        });
        this.$bvModal.hide("modal-tiktok");
        this.isShow = false;
      } else {
        this.errorAlert(result.data.message);
      }
    },
  },
};
</script>

<style></style>
